<template>
  <div class="Userhl_shoucang Userhl_rightbox">
    <el-tabs v-model="activeNamesc" @tab-click="handleClick">
      <el-tab-pane label="项目" name="1">
        <ul class="sc_xiangmuul">
          <li
            class="sc_xiangmuulli"
            v-for="(item, index) in scall"
            :key="index"
          >
            <div class="sc_xiangmuulliimg" @click="xiangmulink(item.id)">
              <img :src="item.titlepic" alt="" />
            </div>
            <div class="sc_xiangmuullicen">
              <div class="sc_xiangmuullicen_l">
                <p>{{ item.title }}</p>
                <p>￥{{ item.money }}</p>
              </div>
              <div class="sc_xiangmuullicen_r">
                {{item.taskpace==0||item.taskpace==1?'报名中':(item.taskpace==1||item.taskpace==2||item.taskpace==3||item.taskpace==4?'进行中':'已完成')}}
              </div>
            </div>
            <div class="sc_xiangmuullibtm">
              <icon-svg icon-class="iconlook" />
              <span>{{ item.browse }}</span>
              <icon-svg icon-class="iconmessages" />
              <span>{{ item.plnum }}</span>
              <icon-svg icon-class="icontotal" />
              <span>{{ item.give_like }}</span>
            </div>
          </li>
          <li class="sc_boxzero" v-if="sc_boxzero"></li>
          <li class="lizero" v-if="lizero">
            <img
              src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
              alt=""
            />
          </li>
        </ul>
        <el-button class="linkbtn" plain @click="linkxm('/xiangmu')">查看更多项目</el-button>
      </el-tab-pane>
      <el-tab-pane label="文章" name="8">
        <ul class="sc_xiangmuul">
          <li
            class="sc_xiangmuulli sc_xiangmuulliwz"
            v-for="(item, index) in scall"
            :key="index"
          >
            <div class="sc_xiangmuulliimg" @click="textlink(item.id)">
              <img :src="item.titlepic" alt="" />
            </div>
            <div class="sc_xiangmuullicen">
              <div class="sc_xiangmuullicen_l sc_xiangmuullicen_lwz">
                <p>{{ item.title }}</p>
                <p>{{ item.create_time }}</p>
              </div>
              <div class="sc_xiangmuullicen_r sc_xiangmuullicen_rwz">
                行业动态
              </div>
            </div>
          </li>
          <li class="sc_boxzero" v-if="sc_boxzero"></li>
          <li class="lizero" v-if="lizero">
            <img
              src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
              alt=""
            />
          </li>
        </ul>
         <el-button class="linkbtn" plain @click="linkxm('/Zixun')">查看更多文章</el-button>
      </el-tab-pane>
      <!-- <el-tab-pane label="简历" name="6">
        <ul class="Userhl_msgznmsgul" style="padding-top: 1rem">
          <li
            class="Userhl_msgznmsgulli sc_jianli_li"
            v-for="(item, index) in scall"
            :key="index"
          >
            <div class="Userhl_msgznmsgulliimg" @click="user(item.id)">
              <img :src="item.userpic" alt="" />
            </div>
            <p>{{ item.username }}</p>
            <p>{{ item.res_school }} - {{ item.major }}</p>
            <div class="sc_jianli_t">
              <span v-for="(bq, index) in item.skill" :key="index">{{
                bq
              }}</span>
            </div>
            <p class="sc_jianlitext">
              {{ item.saytext }}
            </p>
          </li>
          <li class="scjianli_li" v-if="scjianli_li"></li>
          <li class="scjianli_li" v-if="scjianli_li2"></li>
          <li class="lizero" v-if="lizero">
            <img
              src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
              alt=""
            />
          </li>
        </ul>
      </el-tab-pane> -->
      <el-tab-pane label="线上展览" name="44">
        <ul class="sc_zhanping">
          <li class="sc_zhanpingli" v-for="(item, index) in scall" :key="index">
            <div class="sc_xiangmuulliimg">
              <img :src="item.titlepic" alt="" @click="zhanping(item.id)" />
            </div>
            <div class="sc_zhanpinglititle">{{ item.title }}</div>
            <p>
              <span>作者：{{item.username}}</span>
              <span v-if="item.teacher==0?false:true">导师：{{item.teacher}}</span>
            </p>
            <p>
              <span>专业：{{item.activity_one}}</span>
              <span>行业：{{item.activity_two}}</span>
            </p>
            <div class="sc_xiangmuullibtm">
              <icon-svg icon-class="iconlook" />
              <span>{{ item.browse }}</span>
              <icon-svg icon-class="iconmessages" />
              <span>{{ item.plnum }}</span>
              <icon-svg icon-class="icongood" />
              <span>{{ item.give_like }}</span>
            </div>
          </li>
          <li class="sc_boxzero" v-if="sc_boxzero"></li>
          <li class="lizero" v-if="lizero">
            <img
              src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
              alt=""
            />
          </li>
        </ul>
        <el-button class="linkbtn" plain @click="linkxm('/Zhanping')">查看更多展览</el-button>
      </el-tab-pane>
      <el-tab-pane label="成果转化" name="4">
        <ul class="sc_zhanping">
          <li
            class="sc_zhanpingli sc_chengguo"
            v-for="(item, index) in scall"
            :key="index"
          >
            <div class="sc_xiangmuulliimg" @click="chengguo(item.id)">
              <img :src="item.titlepic" alt="" />
            </div>
            <div class="sc_zhanpinglititle">{{ item.title }}</div>
            <p>
              <span>{{ item.activity_one }}-{{ item.activity_two }}</span>
            </p>
            <div class="sc_xiangmuullibtm">
              <icon-svg icon-class="iconlook" />
              <span>{{ item.browse }}</span>
              <icon-svg icon-class="iconmessages" />
              <span>{{ item.plnum }}</span>
              <icon-svg icon-class="icongood" />
              <span>{{ item.give_like }}</span>
            </div>
            <div class="sc_chengguobym">
              <div class="sc_chengguobym_l">
                <img :src="item.userpic" alt="" />
                <span>{{ item.username }}</span>
              </div>
              <div class="sc_chengguobym_r">2020-10-10</div>
            </div>
          </li>
          <li class="sc_boxzero" v-if="sc_boxzero"></li>
          <li class="lizero" v-if="lizero">
            <img
              src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
              alt=""
            />
          </li>
        </ul>
        <el-button class="linkbtn" plain @click="linkxm('/Chengguo')">查看更多作品</el-button>
      </el-tab-pane>
      <el-tab-pane label="教育视频" name="7">
        <ul class="scvideo">
          <li class="scvideoli" v-for="(item, index) in scall" :key="index">
            <div class="sc_xiangmuulliimg" @click="videolink(item.id)">
              <img :src="item.titlepic" alt="" />
            </div>
            <div class="sc_zhanpinglititle">{{ item.title }}</div>
            <div class="scvideoliuser">
              <img :src="item.userpic" alt="" />
              <span>{{ item.username }}</span>
            </div>
            <div class="scvideolibtm">
              <div class="scvideolibtmbox">
                <icon-svg icon-class="iconlook" />
                <span>{{ item.browse }}</span>
                <icon-svg icon-class="iconmessages" />
                <span>{{ item.plnum }}</span>
              </div>
              <span>{{ item.favatime }}</span>
            </div>
          </li>
          <li class="sc_boxzero" v-if="sc_boxzero"></li>
          <li class="lizero" v-if="lizero">
            <img
              src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
              alt=""
            />
          </li>
        </ul>
        <el-button class="linkbtn" plain @click="linkxm('/Jiaoyu')">查看更多课程</el-button>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      lizero:false,
      activeNamesc: "1",
      sc_boxzero: false,
      scjianli_li: false,
      scjianli_li2: false,
      userinfo:{},
      limit: 10,
      word_type: "",
      img: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      scall: [],
      user_info_iostanbqall: [
        {
          text: "规划设计",
          num: "1",
          classred: "",
          ft: "0",
        },
        {
          text: "景观设计",
          num: "2",
          classred: "",
          ft: "0",
        },
        {
          text: "建筑设计",
          num: "3",
          classred: "",
          ft: "0",
        },
        {
          text: "室内设计",
          num: "4",
          classred: "",
          ft: "0",
        },
        {
          text: "软装设计",
          num: "5",
          classred: "",
          ft: "0",
        },
        {
          text: "服装设计",
          num: "6",
          classred: "",
          ft: "0",
        },
        {
          text: "家具设计",
          num: "7",
          classred: "",
          ft: "0",
        },
        {
          text: "交通工具设计",
          num: "8",
          classred: "",
          ft: "0",
        },
        {
          text: "轻工产品设计",
          num: "9",
          classred: "",
          ft: "0",
        },
        {
          text: "机械设备设计",
          num: "10",
          classred: "",
          ft: "0",
        },
        {
          text: "广告设计",
          num: "11",
          classred: "",
          ft: "0",
        },
        {
          text: "包装设计",
          num: "12",
          classred: "",
          ft: "0",
        },
        {
          text: "图书设计",
          num: "13",
          classred: "",
          ft: "0",
        },
        {
          text: "展陈设计",
          num: "14",
          classred: "",
          ft: "0",
        },
        {
          text: "品牌标志设计",
          num: "15",
          classred: "",
          ft: "0",
        },
      ],
    };
  },
  methods: {
    linkxm(link) {
      this.$router.push({ path: link });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    xiangmu(page, cid) {
      var that = this;
      this.lizero=true;
      that.scall = [];
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url:"/api/myCollect",
          method: "post",
          data: {
            page,
            limit: that.limit,
            classid: cid,
            word_type: that.word_type,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.lizero=false;
            var num = res.data.result.list.length % 3;
            if (num == 2) {
              that.sc_boxzero = true;
            }
            if (cid == 6) {
              var num_ji = res.data.result.list.length % 4;
              if (num_ji == "3") {
                that.scjianli_li = true;
              } else if (num_ji == "2") {
                that.scjianli_li = true;
                that.scjianli_li2 = true;
              }
            }
            that.scall = res.data.result.list;
          } else {
            this.lizero=true;
          }
        });
    },
    xiangmulink(id) {
      this.$router.push({ path: "/Xiangmu_C", query: { data: id } });
    },
    textlink(id) {
      this.$router.push({ path: "/Zixun_C", query: { data: id } });
    },
    user(id) {
      this.$router.push({ path: "/Rencai_c", query: { data: id } });
    },
    chengguo(id) {
      this.$router.push({ path: "/Chengguo_c", query: { data: id } });
    },
    zhanping(id) {
      this.$router.push({ path: "/Chengguo_c", query: { data: id } });
    },
    videolink(id) {
      this.$router.push({ path: "/jiaoyu_heji", query: { data: id } });
    },
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    this.xiangmu(1, 1);
  },
  watch: {
    activeNamesc() {
      if (this.activeNamesc == 44) {
        this.word_type = 2;
        this.xiangmu(1, 4);
      } else if (this.activeNamesc == 4) {
        this.word_type = 1;
        this.xiangmu(1, 4);
      } else {
        this.word_type = "";
        this.xiangmu(1, this.activeNamesc);
      }
    },
  },
};
</script>
